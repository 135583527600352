import { FC, useEffect } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
// import Input from "@mui/material/Input";
// import Box from "@mui/material/Box";
import { IReservationForm, IContactUsForm } from "../type";
import { useConfig } from "../../../context/ConfigProvider";
import { EVENTS } from "../../../constants";
import { useUnitInformation } from "../../../context/UnitsInformationProvider";
import { TextMaskCustom } from "../../../components/PhoneNumberField";
// import { UnitTierInformation } from "../../../components/UnitTierInformation";

export const ContactUs: FC<
  Omit<IReservationForm<IContactUsForm>, "typeOfForm">
> = (props) => {
  const { triggerEvent } = useConfig();
  const { reserving } = useUnitInformation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IContactUsForm>({
    defaultValues: {
      firstName: props.formData?.firstName || "",
      lastName: props.formData?.lastName || "",
      email: props.formData?.email || "",
      phoneNumber: props.formData?.phoneNumber || "",
      notes: props.formData?.notes || "",
    },
  });

  useEffect(() => {
    triggerEvent(EVENTS.ON_RESERVATION_FORM_VALIDATION_ERROR, errors);
  }, [errors]);

  const onSubmit: SubmitHandler<IContactUsForm> = props.onFormDataSecondarySubmit!;

  // const availableTiers = useMemo(
  //   () =>
  //     props.unitGroupInformation.tiers.filter(
  //       (x) => x.availableUnitsCount >= 1
  //     ),
  //   [props.unitGroupInformation]
  // );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {/* <Controller
          name="unitGroupTierId"
          rules={{
            required: {
              value: availableTiers.length > 0,
              message: "Unit Group Tier needs to be selected",
            },
          }}
          control={control}
          render={({ field }) => (
            <Grid item xs={12}>
              {availableTiers.map((tier) => (
                <UnitTierInformation
                  key={tier.id}
                  tierInfo={tier}
                  hasError={Boolean(errors.unitGroupTierId)}
                  selected={field.value === tier.id}
                  // selected={props.selectedTier === tier.id}
                  onSelect={() => {
                    triggerEvent(EVENTS.ON_UNITTIER_SELECT, {
                      unitTierId: tier.id,
                    });
                    field.onChange(tier.id);
                  }}
                />
              ))}
              {errors.unitGroupTierId && (
                <Typography variant="body2" color="error">
                  {errors.unitGroupTierId.message}
                </Typography>
              )}
            </Grid>
          )}
        /> */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Call us
          </Typography>
        </Grid>
        {/**Add Facility Number here */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Send a message
          </Typography>
        </Grid>
        <Controller
          name="firstName"
          rules={{
            required: { value: true, message: "First Name is required" },
          }}
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              <TextField
                fullWidth
                inputRef={ref}
                autoComplete="given-name"
                label="First Name"
                placeholder="First Name"
                required
                error={errors.firstName ? true : undefined}
                helperText={errors.firstName?.message || ""}
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        <Controller
          name="lastName"
          rules={{
            required: { value: true, message: "Last Name is required" },
          }}
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              <TextField
                fullWidth
                inputRef={ref}
                autoComplete="family-name"
                label="Last Name"
                required
                error={errors.lastName ? true : undefined}
                helperText={errors.lastName?.message || ""}
                placeholder="Last Name"
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        <Controller
          name="email"
          rules={{
            required: { value: true, message: "Email is required" },
            pattern: {
              value:
                /^(?!\.)(?!.*\.{2})([a-zA-Z0-9._+-]+(?<!\.))@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
              message: "Invalid email address",
            },
          }}
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              <TextField
                fullWidth
                autoComplete="email"
                label="Email"
                inputRef={ref}
                required
                error={errors.email ? true : undefined}
                helperText={errors.email?.message || ""}
                placeholder="Email"
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        <Controller
          name="phoneNumber"
          rules={{
            required: { value: true, message: "Phone Number is required" },
            maxLength: { value: 14, message: "Invalid Phone Number" },
          }}
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              {/* <Box sx={{ display: "none" }}>
                <Input
                  fullWidth
                  inputRef={ref}
                  {...otherFieldProps}
                  autoComplete="tel"
                  required
                  placeholder="Phone Number"
                  id="formatted-text-mask-input"
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  inputComponent={TextMaskCustom as any}
                />
              </Box> */}
              <TextField
                fullWidth
                inputRef={ref}
                autoComplete="tel"
                label="Phone Number"
                placeholder="Phone Number"
                required
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                InputProps={{ inputComponent: TextMaskCustom as any }}
                error={errors.phoneNumber ? true : undefined}
                helperText={errors.phoneNumber?.message || ""}
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        <Controller
          name="notes"
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                inputRef={ref}
                label="Questions"
                placeholder="Questions"
                error={errors.notes ? true : undefined}
                helperText={errors.notes?.message || ""}
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        <Grid item xs={12}>
          <Button
            disabled={reserving}
            // type="submit"
            fullWidth
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
            {reserving && <CircularProgress size={24} />}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
