import { FC, useState } from "react";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { UnitGroupTier } from "../types/UnitGroup";
import { Pricing } from "./UnitsInformation/subComponents/Pricing";

interface UnitTierInformationProps {
  tierInfo: UnitGroupTier;
  selected: boolean;
  onSelect: () => void;
  hasError?: boolean;
}

export const UnitTierInformation: FC<UnitTierInformationProps> = (props) => {
  const [hovered, setHovered] = useState<boolean>(false);
  return (
    <Card
      elevation={hovered ? 5 : 0}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      variant="outlined"
      sx={(theme) => ({
        backgroundColor: props.hasError
          ? alpha(theme.palette.error.main, 0.1)
          : props.selected
          ? alpha(theme.palette.primary.main, 0.1)
          : "transparent",
        mb: 1,
        borderColor: props.hasError ? theme.palette.error.main : undefined,
        cursor: "pointer",
        minHeight: theme.spacing(8),
      })}
      onClick={props.onSelect}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        p={1}
      >
        <Box sx={{ mr: 1 }}>
          <Radio checked={props.selected} />
        </Box>
        <Box flexGrow={1}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h6" color="primary">
              {props.tierInfo.tier.name}
            </Typography>
            <Box
              sx={{
                display: {
                  xs: "block",
                  md: "none",
                },
                ml: 1.5,
              }}
            >
              <Pricing
                startPrice={props.tierInfo.price}
                discountedPriceStart={props.tierInfo.discountedPrice}
                availbleUnits={props.tierInfo.availableUnitsCount}
                pricePer="mo"
                currencySymbol="$"
              />
            </Box>
          </Box>
          <Typography variant="body2" color="textSecondary">
            {props.tierInfo.tier.description}
          </Typography>
        </Box>
        <Box sx={{ display: { xs: "none", md: "block", alignItems: "end" } }}>
          <Pricing
            startPrice={props.tierInfo.price}
            discountedPriceStart={props.tierInfo.discountedPrice}
            availbleUnits={props.tierInfo.availableUnitsCount}
            pricePer="mo"
            currencySymbol="$"
          />
        </Box>
      </Box>
    </Card>
  );
};
