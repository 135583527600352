import { UnitGroup } from "../types/UnitGroup";
import { Get } from "../utils/Api";

export const GetUnitGroups = (
  facilityId: string,
  includeTiers: boolean = true
) =>
  Get<UnitGroup[]>(
    `/v1/facilities/${facilityId}/unit-groups${
      includeTiers ? "?includeTiers=true" : ""
    }`
  );

export const GetUnitGroupSummary = (
  facilityId: string,
  selectedGroupId: string
): Promise<UnitGroup> =>
  Get<UnitGroup[]>(
    `/v1/facilities/${facilityId}/unit-groups/${selectedGroupId}/units-summary`
  ).then((res) => res[0]);
